.skins-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.skins-container_filter {
  display: flex;
  padding: 1vw;
  gap: 1vw;
}

.skins-container_filter > input, select{
  padding: 0.7vw 1.4vw;
}


.skins-container-table-meta{
  overflow-y: auto;
  max-height: 2vw;
  max-width: 2vw;
}

.skins-container td input {
  padding: 0.7vw 0.5vw;
}

.skins-container-title-skin{
  color: white
}

.skins-container-price-skin{
  color: white;
    background: #383838;
    padding: 0.5vw;
    width: 6vw;
    text-align: center;
    border-radius: 0.5vw;
    cursor: pointer;
}

.skins-container-filter-only-allowed{
  display: flex;
  align-items: center;
}

.skins-container-price-skin-integrationPrice{
  color: white;
  background: #383838;
  padding: 0.1vw;
  width: 6vw;
  text-align: center;
  border-radius: 0.1vw;
  cursor: pointer;

  margin-top: 0.1vw;
}
.skins-container-liquidity-skin{
  font-weight: 600;
}