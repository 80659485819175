.skins-on-bot-container {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: 100vh;
}

.skins-on-bot-container_filter {
  display: flex;
  padding: 1vw;
  gap: 1vw;
}

.skins-on-bot-container_filter>input,
select {
  padding: 0.7vw 1.4vw;
}

.skins-on-bot-on-bot-container-skins {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5vw;
}

.skins-on-bot-on-bot-container-skins-skin {
  border-radius: 0.5vw;
    background-color: #191c1e;
    padding: 0.5vw;
    display: flex;
    flex-direction: column;
}

.skins-on-bot-on-bot-container-skins-skin>img {
  width: 6vw;
}

.skins-on-bot-on-bot-container-skins_price{
  font-size: 1.2vw;
  color: white;
  text-align: end;
  font-weight: 600;
  margin-top: 0.5vw;
}

.skins-on-bot-on-bot-container-skins_price_dop:first-child{
  margin-top: 0.5vw;
}

.skins-on-bot-on-bot-container-skins_price_dop{
  font-size: 0.6vw;
  color: rgb(181, 181, 181);

}
.skins-on-bot-on-bot-container-skins_price_bot{
  font-size: 0.6vw;
  color: rgb(181, 181, 181);
}

.skins-on-bot-on-bot-container-skins_price>span{
  font-size: 0.6vw;
  vertical-align: text-top;
}