/* Header.css */

header {
  width: 12vw;
  height: 100vh;
  padding: 1.5vw;
  display: flex;
  background-color: #191c1e;
  gap: 1vw;
  flex-direction: column;
}



nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  flex-direction: column;
}

nav div {
  display: flex;
  gap: 5px;
  align-items: center;
}

nav div a {
  color: #797979;
  text-decoration: none;
  font-size: 1vw;
}

nav div:hover a {
  color: #6eaefa;
  transition: 0.2s;
}


