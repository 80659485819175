@import "./assets/font/fonts.css";

* {
  font-family: "Gilroy", sans-serif;
  font-weight: 500; /* Medium */
  box-sizing: border-box;
}

/* Кастомный скроллбар для Webkit браузеров (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 0.6vw; /* Ширина скроллбара */
}

::-webkit-scrollbar-track {
  background: #191c1e; /* Цвет фона скроллбара */
}

::-webkit-scrollbar-thumb {
  background: #6eadfa7c; /* Цвет бегунка скроллбара */
}

::-webkit-scrollbar-thumb:hover {
  background: #4883aa; /* Цвет бегунка при наведении */
}
body {
  margin: 0;
  background-color: #0f1114;

}

#root{
  display: flex;
  flex-direction: row;
}

.vertical-line {
  border-left: 2px solid rgba(255, 255, 255, 0.1);
}
.a {
  text-decoration: none;
  color: #666;
}

button {
  border: none;
  outline: none;
  cursor: pointer;

  font-size: 0.8vw;
  background-color: rgba(110, 174, 250, 1);
  padding: 0.5vw;
  border-radius: 0.3vw;
  color: white;
}

a:active, /* активная/посещенная ссылка */
a:hover,  /* при наведении */
a {
  text-decoration: none;
}

label{
  /* color: white; */
  font-size: 1vw;
}

.link {
  width: auto;
  text-decoration: none;
  background-color: #343538;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.danger {
  color: #ae3232;
}

input:focus, textarea:focus, select:focus {
  outline: none !important;
}
input, select {
  background-color: rgba(121, 121, 121, 0.16);
  outline:none;
  border: none;
  padding: 1vw 2vw;
  border-radius: 0.7vw;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 0.8vw;
}

option {
  color: black;
}

.pagination a{
  color: white;
  cursor: pointer;
  font-size: 1vw;
}

.pagination{
  list-style-type: none;
  background-color: rgba(40, 42, 44, 0.5);
  display: flex;
  gap: 1vw;
  justify-content: center;
  align-items: center;
  padding: 0.6vw;
}

.pagination .active{
  background-color: rgba(110, 174, 250, 1);
color: black;
padding: 0.3vw;
width: 1.7vw;
text-align: center;
height: 1.7vw;
border-radius: 0.5vw;
}


table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 1vw;
  text-align: left;
}

th {
  background-color: rgba(40, 42, 44, 0.5);
  font-family: "Montserrat", sans-serif;
  color: #bbb;
  font-size: 0.8vw;
}

td {
  color: #fff;
  font-size: 0.8vw;
}

td span {
  display: block;

  color: #888;
}

td > a {
  color: rgba(121, 121, 121, 1);
}

.status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
}

.status.success {
  background-color: rgba(65, 102, 94, 0.74);
  color: rgba(86, 174, 154, 1);
  font-size: 1vw;
}

.status.create {
  background-color: rgba(102, 98, 65, 0.74);
  color: rgb(161, 174, 86);
  font-size: 1vw;
}
.status.paymenting {
  background-color: rgba(65, 98, 102, 0.74);
  color: rgb(86, 168, 174);
  font-size: 1vw;
}
.status.cancel {
  background-color: rgba(102, 65, 65, 0.74);
  color: rgb(174, 86, 86);
  font-size: 1vw;
}
.balance {
}

.balance.positive {
  color: rgba(86, 174, 154, 1);
}

.balance.negative {
  color: #dc3545;
}

.container{
  width: 100%;
}


.react-contextmenu-item:hover{
  background-color: rgb(82, 82, 82);
}
.react-contextmenu-item{
  color: white;
  background-color: rgb(61, 61, 61);
  padding: 0.5vw;
  font-size: 0.8vw;
  width: 100%;
  cursor: pointer;
}

.react-contextmenu{
  gap: 0;
  z-index:9999
}