.users-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.users-container_filter {
  display: flex;
  padding: 1vw;
  gap: 1vw;
}

.users-container_filter > input, select{
  padding: 0.7vw 1.4vw;
}

.users-container-table-meta{
  overflow-y: auto;
  max-height: 2vw;
  max-width: 2vw;
}

.users-container td input {
  padding: 0.7vw 0.5vw;
}