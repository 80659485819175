.bots-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.bots-container_filter {
  display: flex;
  padding: 1vw;
  gap: 1vw;
}

.bots-container_filter > input, select{
  padding: 0.7vw 1.4vw;
}

.bots-table-img{
 border-radius: 0.5vw;
 width: 3vw;
}

